class FormatList {
	/**
	 * Create and initialise objects of this class
	 * @param {object} block
	 */
	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		// image
		const imageContainer = this.block.querySelector('.image-container');
		this.dots = this.block.querySelectorAll('.dot-pagination .dot');

		this.dots.forEach( dot => {
			dot.addEventListener('click', () => this.dotClick(dot) );
		});

		ScrollTrigger.matchMedia({
			"(min-width: 768px)": () => {
				ScrollTrigger.create({
					trigger: imageContainer,
					start: 'top 15%',
					endTrigger: this.block,
					end: 'bottom bottom',
					// markers: true,
					pin: true,
				});
		
				// items
				const items = this.block.querySelectorAll('.content-list-container .item');
				this.items = items;
				this.images = this.block.querySelectorAll('.image-container .asset');
				
				items.forEach( (item, index) => {
					ScrollTrigger.create({
						trigger: item,
						start: '20% 60%',
						end: 'bottom 50%',
						// markers: true,
						onEnter: () => {
							this.toggleImage( item, index );
						},
						onEnterBack: () => {
							this.toggleImage( item, index );
						},
					});
		
					let animation = gsap.timeline({ paused: true });
					animation
						.to(item, { opacity: 1, duration: 0.2 })
						.to(item, { opacity: 0, duration: 0.2 }, 0.8 )
					;
		
					ScrollTrigger.create({
						trigger: item,
						start: '10% 70%',
						end: 'bottom 50%',
						// markers: true,
						animation,
						scrub: true,
						toggleActions: "play reverse play reverse",
					});
				});
			}
		})

		// add an intersection observer to the block that as soon as it enters the viewport, it runs ScrollTrigger.refresh();
		const observer = new IntersectionObserver( entries => {
			entries.forEach( entry => {
				if ( entry.isIntersecting ) {
					ScrollTrigger.refresh();
				}
			});
		});

		observer.observe(this.block);

		
	}

	toggleImage( item ) {
		const index = item.dataset.index;
		this.images.forEach( image => image.classList.remove('active') );
		this.dots.forEach( dot => dot.classList.remove('active') );
		this.dots[index].classList.add('active');
		this.images[index].classList.add('active');
	}

	dotClick( dot ) {
		const index = dot.dataset.index;
		this.images.forEach( image => image.classList.remove('active') );
		this.dots.forEach( dot => dot.classList.remove('active') );
		this.dots[index].classList.add('active');
		this.images[index].classList.add('active');

		const item = this.block.querySelector(`.content-list-container .item[data-index="${index}"]`);
		item.scrollIntoView({ behavior: 'smooth', block: 'center'});

	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-format-list').forEach( block => new FormatList(block) );
});
